<template>
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_10205)">
      <path d="M176.764 153.642L79.7563 194.251C78.4097 194.816 76.931 194.988 75.4907 194.748C74.0505 194.509 72.7072 193.867 71.616 192.897C70.5247 191.927 69.7298 190.668 69.3229 189.266C68.9161 187.863 68.9138 186.375 69.3164 184.971L97.4629 86.7943L97.5587 87.3119L97.8655 88.3569L98.3544 89.5648L99.0255 90.9836L99.9842 92.6134L101.269 94.6553L102.86 96.9561L104.777 99.5445L106.8 102.066L109.101 104.788L111.718 107.722L114.383 110.598L117.259 113.599L120.433 116.743L123.644 119.792L127.057 122.936L130.671 126.147L134.343 129.302L137.919 132.264L141.399 135.034L144.936 137.757L148.301 140.24L151.503 142.493L154.762 144.688L157.763 146.605L160.534 148.274L163.362 149.846L165.864 151.121L168.059 152.079L169.794 152.76L171.29 153.239L172.555 153.556L173.629 153.719L174.588 153.767L175.345 153.69L175.958 153.517L176.39 153.287L176.764 153.642Z" fill="url(#paint0_linear_600_10205)"/>
      <path d="M177.049 151.171L176.962 151.794L176.771 152.321L176.483 152.753L176.1 153.088L176.042 153.117L175.611 153.347L174.997 153.52L174.24 153.596L173.3 153.548L172.227 153.385L170.961 153.069L169.466 152.59L167.73 151.909L165.535 150.922L163.033 149.647L160.205 148.074L157.434 146.406L154.434 144.489L151.174 142.294L147.972 140.041L144.607 137.558L141.07 134.835L137.59 132.065L134.014 129.102L130.342 125.948L126.728 122.737L123.315 119.592L120.094 116.525L116.921 113.38L114.007 110.38L111.342 107.523L108.724 104.589L106.424 101.867L104.401 99.3453L102.455 96.7569L100.863 94.4561L99.5788 92.4141L98.6681 90.7844L97.997 89.3656L97.5081 88.1577L97.2013 87.1127L97.1055 86.595L97.0288 86.1828L97 85.4255L97.0863 84.7927L97.278 84.2751L97.5656 83.8437L97.9491 83.5081L98.438 83.2493L99.0515 83.0767L99.8089 83L100.748 83.0384L101.832 83.2109L103.097 83.5273L104.583 84.0066L106.328 84.6873L108.523 85.6747L111.016 86.9401L113.844 88.522L116.624 90.19L119.625 92.1074L122.884 94.3027L126.076 96.5556L129.441 99.0385L132.979 101.761L136.459 104.532L140.035 107.494L143.706 110.648L147.33 113.859L150.743 117.004L153.964 120.072L157.128 123.216L160.042 126.207L162.717 129.074L165.324 132.007L167.635 134.73L169.657 137.241L171.603 139.839L173.195 142.14L174.47 144.182L175.381 145.812L176.061 147.221L176.541 148.439L176.857 149.484L177.02 150.413L177.049 151.171Z" fill="url(#paint1_linear_600_10205)"/>
      <path d="M183.484 105.584C183.484 106.415 183.238 107.228 182.777 107.919C182.315 108.611 181.66 109.15 180.893 109.469C180.125 109.789 179.28 109.874 178.465 109.714C177.649 109.554 176.899 109.156 176.31 108.57C175.72 107.984 175.317 107.237 175.151 106.423C174.986 105.608 175.065 104.763 175.379 103.993C175.693 103.224 176.228 102.565 176.916 102.099C177.605 101.633 178.416 101.381 179.247 101.376C179.802 101.37 180.353 101.476 180.868 101.685C181.382 101.894 181.85 102.204 182.244 102.595C182.638 102.987 182.951 103.452 183.164 103.965C183.376 104.478 183.485 105.029 183.484 105.584Z" :fill="primaryColor"/>
      <path d="M148.454 62.1278C148.456 62.9605 148.211 63.7752 147.75 64.4685C147.289 65.1619 146.632 65.7028 145.863 66.0228C145.094 66.3428 144.248 66.4275 143.431 66.2662C142.614 66.1049 141.863 65.7048 141.273 65.1166C140.684 64.5284 140.282 63.7786 140.119 62.9619C139.956 62.1453 140.039 61.2986 140.357 60.529C140.675 59.7595 141.214 59.1016 141.907 58.6387C142.599 58.1759 143.413 57.9288 144.246 57.9288C145.36 57.9288 146.429 58.3709 147.218 59.1581C148.007 59.9452 148.452 61.0132 148.454 62.1278Z" :fill="primaryColor"/>
      <path d="M152.337 80.6779C152.261 80.3532 152.078 80.0635 151.817 79.8561C151.556 79.6488 151.232 79.5359 150.899 79.5359C150.565 79.5359 150.242 79.6488 149.981 79.8561C149.72 80.0635 149.536 80.3532 149.461 80.6779L148.991 82.5953C148.927 82.8577 148.792 83.0974 148.601 83.2884C148.41 83.4794 148.17 83.6144 147.908 83.6786L145.99 84.1483C145.658 84.2151 145.359 84.3948 145.145 84.6569C144.93 84.9191 144.813 85.2475 144.813 85.5863C144.813 85.9251 144.93 86.2535 145.145 86.5157C145.359 86.7778 145.658 86.9575 145.99 87.0243L147.908 87.5036C148.171 87.5668 148.411 87.7014 148.602 87.8926C148.793 88.0838 148.928 88.3241 148.991 88.5869L149.461 90.5043C149.536 90.829 149.72 91.1187 149.981 91.3261C150.242 91.5334 150.565 91.6463 150.899 91.6463C151.232 91.6463 151.556 91.5334 151.817 91.3261C152.078 91.1187 152.261 90.829 152.337 90.5043L152.807 88.5869C152.868 88.3231 153.002 88.0817 153.193 87.8902C153.385 87.6987 153.626 87.5648 153.89 87.5036L155.807 87.0243C156.139 86.9575 156.438 86.7778 156.653 86.5157C156.868 86.2535 156.985 85.9251 156.985 85.5863C156.985 85.2475 156.868 84.9191 156.653 84.6569C156.438 84.3948 156.139 84.2151 155.807 84.1483L153.89 83.6786C153.627 83.6154 153.387 83.4808 153.196 83.2896C153.004 83.0984 152.87 82.8581 152.807 82.5953L152.337 80.6779Z" :fill="primaryColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M134.41 48.3325C134.614 48.8513 134.605 49.43 134.384 49.9421C134.163 50.4541 133.748 50.8578 133.231 51.0647C130.134 52.2727 126.184 56.1744 123.548 61.0445C121.544 64.7449 120.567 68.4837 120.969 71.5898C122.106 69.8855 123.622 68.4679 125.398 67.4484C128.466 65.7899 131.975 65.9624 135.368 68.2824C136.672 69.0839 137.723 70.2367 138.402 71.6085C139.08 72.9803 139.358 74.5155 139.203 76.038C138.98 77.4415 138.462 78.7816 137.682 79.9695C136.902 81.1574 135.878 82.1659 134.679 82.9282C133.48 83.6906 132.132 84.1893 130.725 84.3914C129.319 84.5935 127.885 84.4943 126.52 84.1004C124.587 83.4775 122.845 82.3722 121.458 80.8889C119.827 87.366 120.185 94.1841 122.484 100.455C122.605 100.717 122.671 101.002 122.677 101.29C122.684 101.579 122.631 101.866 122.522 102.133C122.413 102.401 122.251 102.643 122.044 102.845C121.838 103.047 121.593 103.205 121.323 103.308C121.053 103.411 120.765 103.458 120.477 103.445C120.188 103.433 119.906 103.361 119.646 103.235C119.386 103.108 119.155 102.93 118.967 102.711C118.779 102.492 118.639 102.236 118.553 101.96C115.535 93.8077 115.474 84.8542 118.381 76.6611L118.151 76.2489C115.361 70.9667 117.029 64.3231 119.905 58.9929C122.781 53.6627 127.325 48.8598 131.687 47.1438C131.944 47.0429 132.218 46.9937 132.494 46.9988C132.77 47.004 133.042 47.0634 133.295 47.1738C133.548 47.2843 133.776 47.4434 133.968 47.6422C134.159 47.8411 134.309 48.0756 134.41 48.3325ZM123.05 76.3064C124.137 78.0504 125.777 79.3801 127.709 80.0836C128.53 80.3356 129.398 80.395 130.246 80.257C131.094 80.119 131.899 79.7874 132.598 79.2879C133.247 78.8671 133.801 78.3141 134.222 77.6649C134.643 77.0157 134.923 76.2849 135.043 75.5203C135.115 74.7514 134.961 73.9779 134.598 73.2961C134.235 72.6144 133.679 72.0543 133.001 71.6857C130.776 70.171 128.965 70.2381 127.402 71.0817C125.454 72.3408 123.936 74.1632 123.05 76.3064Z" :fill="primaryColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M202.255 130.557C180.205 122.37 163.362 132.551 157.907 138.313C157.252 138.899 156.401 139.219 155.522 139.208C154.643 139.197 153.8 138.856 153.16 138.254C152.521 137.651 152.13 136.83 152.066 135.954C152.003 135.077 152.27 134.208 152.816 133.52C159.738 126.205 179.458 114.634 204.699 124.019C205.503 124.388 206.138 125.047 206.476 125.865C206.813 126.682 206.829 127.597 206.519 128.426C206.209 129.254 205.597 129.935 204.806 130.33C204.015 130.726 203.103 130.807 202.255 130.557Z" :fill="primaryColor"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_600_10205" x1="64" y1="138.5" x2="127.5" y2="179" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="0.99" :stop-color="primaryColor" stop-opacity="0.6"/>
      </linearGradient>
      <linearGradient id="paint1_linear_600_10205" x1="97" y1="118.298" x2="177.049" y2="118.298" gradientUnits="userSpaceOnUse">
        <stop :stop-color="primaryColor"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <clipPath id="clip0_600_10205">
        <rect width="138" height="147.865" fill="white" transform="translate(69 47)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'Celebration',
  props: {
    primaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_primary')
    },
    secondaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_secondary')
    }
  }
}
</script>

<style scoped>

</style>
