<template>
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_10221)">
      <path d="M179.74 192C179.903 192.01 180.067 192.01 180.23 192C180.077 192.01 179.923 192.01 179.77 192H179.74Z" :fill="secondaryColor" fill-opacity="0.4"/>
      <path d="M76.9999 108.66V118.08L70.2599 113.51C70.2124 113.478 70.1569 113.461 70.0999 113.46C70.045 113.44 69.9848 113.44 69.9299 113.46C69.8755 113.486 69.8302 113.528 69.7999 113.58C69.7963 113.607 69.7963 113.633 69.7999 113.66V113.2L76.9999 108.66Z" :fill="secondaryColor" fill-opacity="0.4"/>
      <path d="M138.72 83.57H117L127 77.32C127.258 77.158 127.556 77.0714 127.86 77.07C128.16 77.0692 128.455 77.1523 128.71 77.31L138.72 83.57Z" :fill="secondaryColor" fill-opacity="0.4"/>
      <path d="M186 113.55C186.019 113.495 186.019 113.435 186 113.38C185.97 113.328 185.924 113.286 185.87 113.26C185.814 113.245 185.756 113.245 185.7 113.26C185.643 113.269 185.589 113.29 185.54 113.32L178.21 118.32V108.08L184.71 112.08L186 113V113.55Z" :fill="secondaryColor" fill-opacity="0.4"/>
      <path d="M177.68 83.57H77.46C77.4011 83.5659 77.3419 83.5739 77.2862 83.5937C77.2305 83.6134 77.1795 83.6445 77.1364 83.6848C77.0932 83.7252 77.0588 83.774 77.0354 83.8282C77.0119 83.8824 76.9999 83.9409 77 84V118.08L116.28 144.69L126.81 135.37C127.106 135.11 127.486 134.966 127.88 134.966C128.274 134.966 128.654 135.11 128.95 135.37L139.52 144.65L178.08 118.3V84C178.08 83.891 178.039 83.7859 177.965 83.7061C177.891 83.6262 177.789 83.5776 177.68 83.57ZM102.68 110.19C100.68 110.19 99.05 108.02 99.05 105.35C99.05 102.68 100.67 100.51 102.68 100.51C104.69 100.51 106.31 102.67 106.31 105.35C106.31 108.03 104.68 110.19 102.68 110.19ZM142.43 125.87C142.368 125.981 142.278 126.073 142.17 126.14C142.057 126.203 141.93 126.238 141.8 126.24C141.678 126.24 141.557 126.209 141.45 126.15C141.347 126.086 141.258 126 141.19 125.9C141.19 125.84 137.19 119.74 128.03 118.97C124.172 118.668 120.339 119.796 117.26 122.14C115.777 123.203 114.459 124.481 113.35 125.93C113.266 126.043 113.151 126.129 113.019 126.178C112.887 126.227 112.744 126.236 112.606 126.206C112.469 126.175 112.343 126.105 112.245 126.005C112.146 125.905 112.078 125.778 112.05 125.64C112.019 125.451 112.062 125.258 112.17 125.1C113.356 123.53 114.772 122.148 116.37 121C119.742 118.447 123.933 117.216 128.15 117.54C138.08 118.36 142.24 124.86 142.42 125.13C142.482 125.244 142.519 125.37 142.53 125.5C142.531 125.63 142.497 125.758 142.43 125.87ZM153.5 110.19C151.5 110.19 149.87 108.02 149.87 105.35C149.87 102.68 151.5 100.51 153.5 100.51C155.5 100.51 157.13 102.67 157.13 105.35C157.13 108.03 155.51 110.19 153.5 110.19Z" :fill="secondaryColor"/>
      <path d="M186.1 185.07V185.54C186.1 185.76 186.1 185.98 186.1 186.2C186.084 186.362 186.057 186.522 186.02 186.68C186.02 186.58 186.02 186.48 186.02 186.39C186.06 186.127 186.029 185.857 185.93 185.61C185.838 185.361 185.683 185.141 185.48 184.97L178.1 178.49L139.55 144.64L178.11 118.29L185.44 113.29C185.489 113.26 185.543 113.239 185.6 113.23C185.656 113.215 185.714 113.215 185.77 113.23C185.824 113.256 185.87 113.298 185.9 113.35C185.919 113.405 185.919 113.465 185.9 113.52L186.1 185.07Z" fill="url(#paint0_linear_600_10221)"/>
      <path d="M116.31 144.69L76.9999 179.43L70.4899 185.19C70.2915 185.367 70.1403 185.59 70.0499 185.84C69.9789 186.018 69.9416 186.208 69.9399 186.4C69.9399 186.2 69.9399 186 69.9399 185.8L69.7899 113.69C69.7863 113.663 69.7863 113.637 69.7899 113.61C69.8202 113.558 69.8655 113.516 69.9199 113.49C69.9748 113.47 70.035 113.47 70.0899 113.49C70.1469 113.491 70.2024 113.508 70.2499 113.54L76.9999 118.08L116.31 144.69Z" fill="url(#paint1_linear_600_10221)"/>
      <path d="M185.94 185.61C185.848 185.361 185.693 185.141 185.49 184.97L178.11 178.49L139.55 144.64L129 135.36C128.704 135.1 128.324 134.956 127.93 134.956C127.536 134.956 127.156 135.1 126.86 135.36L116.33 144.68L76.9999 179.43L70.4899 185.19C70.2915 185.367 70.1403 185.59 70.0499 185.84C69.9789 186.018 69.9416 186.208 69.9399 186.4C69.9357 186.447 69.9357 186.493 69.9399 186.54C69.9399 186.54 69.9399 186.61 69.9399 186.64C69.9343 186.713 69.9343 186.787 69.9399 186.86V187.02C69.9604 187.152 69.9904 187.282 70.0299 187.41L70.1199 187.71C70.1799 187.89 70.2399 188.06 70.3099 188.23C70.3799 188.4 70.4799 188.6 70.5799 188.78C70.6799 188.96 70.7599 189.11 70.8599 189.27C70.9399 189.38 71.0299 189.5 71.0999 189.61V189.67L71.2699 189.85L71.4699 190.06C71.6024 190.204 71.7426 190.341 71.8899 190.47L71.7999 190.41C72.3431 190.923 72.9773 191.33 73.6699 191.61C74.4542 191.929 75.2932 192.092 76.1399 192.09L179.66 192C180.508 192.004 181.348 191.837 182.13 191.51C182.912 191.186 183.623 190.71 184.22 190.11C184.819 189.508 185.295 188.795 185.62 188.01C185.719 187.76 185.8 187.502 185.86 187.24V187.19C185.872 187.112 185.888 187.036 185.91 186.96L185.97 186.67C185.97 186.57 185.97 186.47 185.97 186.38C186.028 186.125 186.017 185.86 185.94 185.61ZM184.26 190.06C184.154 190.171 184.04 190.275 183.92 190.37C184.06 190.25 184.19 190.12 184.32 189.99L184.26 190.06ZM185.93 186.97C185.908 187.046 185.892 187.122 185.88 187.2C185.908 187.111 185.931 187.021 185.95 186.93C185.95 186.93 185.94 187 185.93 187V186.97ZM71.7799 190.29L71.3899 189.86C71.5292 190.039 71.6795 190.209 71.8399 190.37L71.9599 190.47L71.7799 190.29Z" :fill="secondaryColor" fill-opacity="0.4"/>
      <path d="M67.4799 74.8C67.823 74.4392 68.0099 73.9577 67.9999 73.46C67.9844 72.9616 67.7765 72.4886 67.4199 72.14L54.3299 58.72C54.1532 58.5283 53.9393 58.3744 53.7014 58.2676C53.4635 58.1608 53.2064 58.1033 52.9456 58.0986C52.6849 58.0939 52.4259 58.1422 52.1843 58.2404C51.9427 58.3386 51.7235 58.4847 51.5399 58.67L50.5399 59.76C50.3642 59.9519 50.229 60.1773 50.1426 60.4228C50.0562 60.6683 50.0203 60.9287 50.0371 61.1884C50.0539 61.4481 50.1231 61.7017 50.2404 61.934C50.3578 62.1662 50.5209 62.3724 50.7199 62.54L64.8099 74.88C65.1823 75.2085 65.6635 75.3867 66.1599 75.38C66.6593 75.3687 67.1339 75.1602 67.4799 74.8Z" :fill="secondaryColor"/>
      <path d="M75.2099 70.2C75.3527 70.1308 75.4816 70.0359 75.5899 69.92C75.6996 69.8012 75.7846 69.6619 75.8399 69.51C75.8955 69.3631 75.9226 69.207 75.9199 69.05C75.9095 68.8872 75.8688 68.7278 75.7999 68.58L70.8999 57.41C70.8338 57.2505 70.7359 57.1062 70.6121 56.9858C70.4883 56.8655 70.3412 56.7716 70.1799 56.71C70.0216 56.6434 69.8516 56.6092 69.6799 56.6092C69.5082 56.6092 69.3382 56.6434 69.1799 56.71L68.2799 57.12C68.125 57.1873 67.988 57.2901 67.8799 57.42C67.7617 57.54 67.6757 57.6879 67.6299 57.85C67.5757 58.0107 67.5552 58.181 67.5699 58.35C67.5866 58.52 67.6413 58.684 67.7299 58.83L73.5299 69.6C73.6858 69.8903 73.9446 70.1118 74.2555 70.2209C74.5663 70.3301 74.9068 70.3191 75.2099 70.19V70.2Z" :fill="secondaryColor"/>
      <path d="M83.3399 72C83.6184 72.0162 83.8974 71.9773 84.1608 71.8855C84.4243 71.7937 84.667 71.6508 84.8751 71.465C85.0832 71.2792 85.2526 71.0541 85.3735 70.8027C85.4945 70.5513 85.5646 70.2785 85.5799 70L89.5199 47.15C89.6818 46.5693 89.6142 45.9486 89.3313 45.4163C89.0484 44.884 88.5718 44.4808 87.9999 44.29L86.4399 44C85.3499 43.81 84.3099 44.8 84.1699 46.19L81.7499 69.36C81.6413 69.9188 81.7437 70.498 82.0374 70.9857C82.3311 71.4733 82.7952 71.8347 83.3399 72Z" :fill="secondaryColor"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_600_10221" x1="139.55" y1="149.95" x2="186.1" y2="149.95" gradientUnits="userSpaceOnUse">
        <stop :stop-color="secondaryColor" stop-opacity="0.05"/>
        <stop offset="1" :stop-color="secondaryColor" stop-opacity="0.2"/>
      </linearGradient>
      <linearGradient id="paint1_linear_600_10221" x1="69.7899" y1="149.92" x2="116.31" y2="149.92" gradientUnits="userSpaceOnUse">
        <stop :stop-color="secondaryColor" stop-opacity="0.2"/>
        <stop offset="1" :stop-color="secondaryColor" stop-opacity="0.05"/>
      </linearGradient>
      <clipPath id="clip0_600_10221">
        <rect width="136.1" height="148.24" fill="white" transform="translate(50 44)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Unfortunate',
  props: {
    primaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_primary')
    },
    secondaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_secondary')
    }
  }
}
</script>

<style scoped>

</style>
