<template>
  <div class="main">
    <div class="main-content animate__animated animate__bounceIn">
      <Unfortunate v-if="layouts.data.SVGControl === 'unfortunate'" />
      <Exclamation v-else-if="layouts.data.SVGControl === 'exclamation'" />
      <Celebration v-else-if="layouts.data.SVGControl === 'celebration'" />
      <div class="text-center f-s-20 f-w-900">{{ layouts.data.title }}</div>
      <div class="py-10"><div v-html="layouts.data.text"></div></div>
    </div>

    <div class="width-30p text-center bottom-wrap py-2" v-if="layouts.actives.accountTip">
      <div class="d-flex align-center mt-8">
        <v-divider></v-divider>
        <span class="px-4">For More</span>
        <v-divider></v-divider>
      </div>
      <p class="mt-2">
        <span class="primary-text" @click="onClickSignIn">Sign In</span>
        or <span class="primary-text" @click="onClickRegister">Create Account</span>
      </p>
    </div>

    <div class="footer-wrap" v-if="layouts.actives.backBtn">
      <web-btn
        theme="primary"
        :height="50"
        width="100%"
        @click="returnHome"
      >
        Home
      </web-btn>
    </div>
  </div>
</template>

<script>
import { DataType, Tool } from '@/assets/js/util'
import WebBtn from '@/components/base/WebBtn'
import Unfortunate from '@/components/svg/Unfortunate'
import Exclamation from '@/components/svg/Exclamation'
import Celebration from '@/components/svg/Celebration'
import { OnlineApi } from '@/api'

export default {
  components: { Exclamation, Unfortunate, Celebration, WebBtn },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      layouts: {
        data: {
          title: '',
          text: '',
          SVGControl: '',
          approvedAmount: null,
          disbursementDate: null,
          accountNo: null
        },
        actives: {
          backBtn: false,
          accountTip: false
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assists.tool.isNotEmpty(val)) {
          this.initPage()
        }
      },
      deep: true
    }
  },
  created () {
  },
  mounted () {
    this.initPage()
  },
  methods: {
    initPage () {
      const notificationType = this.$route.params.code
      switch (Number(notificationType)) {
        case DataType.NotificationPageType.APPLICATION_SUCCESS.value:
          // application 申请成功的情况包括常规流程和paper check
          this.layouts.actives.accountTip = true
          this.getDisbursementInfo(DataType.NotificationPageType.APPLICATION_SUCCESS)
          break
        case DataType.NotificationPageType.NVR_CUSTOMER_PAGE.value:
          // NVR customer page
          this.getPersonalInfo(DataType.NotificationPageType.NVR_CUSTOMER_PAGE)
          break
        case DataType.NotificationPageType.WITHDRAWN_NOTE.value:
          // 当前loan被withDraw
          this.pageRefresh(DataType.NotificationPageType.WITHDRAWN_NOTE)
          break
        case DataType.NotificationPageType.INVALID_LINK.value:
          // 链接已失效
          this.invalidLinkScenario(DataType.NotificationPageType.INVALID_LINK)
          break
        case DataType.NotificationPageType.PENDING_NOTE.value:
          // 当前loan为pending状态
          this.getDisbursementInfo(DataType.NotificationPageType.PENDING_NOTE)
          break
        case DataType.NotificationPageType.ORIGINATING_NOT_CUSTOMER_REVIEW.value:
          // loan的状态为Originating 但是subStatus不为Customer Review
          this.pageRefresh(DataType.NotificationPageType.ORIGINATING_NOT_CUSTOMER_REVIEW)
          break
        case DataType.NotificationPageType.CALCULATE_NO_AMOUNT.value:
          // 走到了计算，但是因为未知的原因并没有Loan amount，跳到提示页，并让其重走guidance
          this.pageRefresh(DataType.NotificationPageType.CALCULATE_NO_AMOUNT)
          break
        case DataType.NotificationPageType.PAPER_CHECK.value:
          // loan走的paper check后再次点击链接
          this.pageRefresh(DataType.NotificationPageType.PAPER_CHECK)
          break
        case DataType.NotificationPageType.UPLOAD_BANK_STATEMENT_SUCCESS.value:
          this.pageRefresh(DataType.NotificationPageType.UPLOAD_BANK_STATEMENT_SUCCESS)
          break
        case DataType.NotificationPageType.CANCEL_UPLOAD_STATEMENT.value:
          this.initWithPortfolioInfo(DataType.NotificationPageType.CANCEL_UPLOAD_STATEMENT)
          break
        case DataType.NotificationPageType.PAYMENT_SUCCESS.value:
          // Croc完成payment操作
          this.pageRefresh(DataType.NotificationPageType.PAYMENT_SUCCESS)
          break
        default:
          break
      }
    },
    pageRefresh (setting) {
      this.layouts.data.title = setting.title
      this.layouts.data.text = setting.text
      this.layouts.actives.backBtn = setting.action
      this.layouts.data.SVGControl = setting.SVGControl
    },
    invalidLinkScenario (setting) {
      this.layouts.data.title = setting.title
      this.layouts.actives.backBtn = setting.action
      this.layouts.data.SVGControl = setting.SVGControl
      const portfolio = this.$store.getters.getPortfolioJson
      this.layouts.data.text = `The link has expired. Please contact our Customer Support team at ${portfolio.hotlinePhone} or via email at ${portfolio.email}`
    },
    getDisbursementInfo (setting) {
      const loanNo = this.$store.getters.getLoan.loanNo
      const loanInfo = new Promise((resolve, reject) => {
        OnlineApi.getLoanInfo(loanNo, (result) => {
          this.layouts.data.approvedAmount = result.approvedPrincipal
          this.layouts.data.disbursementDate = result.disbursementDate
          resolve()
        })
      })
      const bankInfo = new Promise((resolve, reject) => {
        OnlineApi.getBankInfo(loanNo, (result) => {
          this.layouts.data.accountNo = result.accountNo
          resolve()
        })
      })

      Promise.all([loanInfo, bankInfo]).then(() => {
        if (setting.value === DataType.NotificationPageType.APPLICATION_SUCCESS.value) {
          this.layouts.data.text = '<div class="mb-2">Your signed loan agreement has been submitted to the Tribal Loan Closing Agent for final review and approval.</div>' +
          `<div class="mb-2">If your loan application is Approved, your loan proceeds will be deposited to your bank account ending in ${this.layouts.data.accountNo.substring(this.layouts.data.accountNo.length - 4)} on ${this.layouts.data.disbursementDate}.</div>` +
          '<div class="mb-2">If your loan application is Denied, we will send you a denial letter that explains the reason for the denial via email within 30 days. </div>' +
          '<div class="mb-2">You can also download your signed copies of the loan agreement by clicking the link in your email that we have just sent you. If you do not see the email in a few minutes, please check your "junk mail" folder or "spam" folder.</div>' +
          '<div class="mb-2">Thank you for choosing us for your short-term financial need! You can now close this window. '
        } else {
          this.layouts.data.text = 'Congratulations! Your application has been approved.\n' +
            `${this.assists.tool.formatCurrency(this.layouts.data.approvedAmount)} will be deposited to your account no. ${this.layouts.data.accountNo.substring(this.layouts.data.accountNo.length - 4)} on ${this.layouts.data.disbursementDate}, please contact us if you have any questions.`
        }
        this.layouts.data.title = setting.title
        this.layouts.actives.backBtn = setting.action
        this.layouts.data.SVGControl = setting.SVGControl
      })
    },
    getPersonalInfo (setting) {
      OnlineApi.getPersonalData((result) => {
        if (this.assists.tool.isNotEmpty(result)) {
          this.layouts.data.text = '<div class="mb-2">Well done! We have received your application, and it is under review.</div>' +
            `<div class="mb-2">If you are pre-qualified, we will notify you via email (<span class="f-w-900">${result.email}</span>) about the next steps. You can follow the step-by-step instructions to sign your loan agreement electronically. Please keep an eye on your email inbox (and your spam folder, just in case). </div>` +
            `<div class="mb-2">We may also call you at <span class="f-w-900">${this.assists.tool.formatPhone(result.cellPhone)} </span>. Please keep your phone on.</div>`
          this.layouts.data.title = setting.title
          this.layouts.actives.backBtn = setting.action
          this.layouts.data.SVGControl = setting.SVGControl
        }
      })
    },
    initWithPortfolioInfo (setting) {
      this.layouts.data.title = setting.title
      const portfolio = this.$store.getters.getPortfolioJson
      this.layouts.data.text = `Thank you for considering uploading your bank statements. While it's optional, providing them helps us better understand your financial situation and tailor our services accordingly. If you have any concerns or need assistance, please contact our Customer Support team at ${portfolio.hotlinePhone} or via email at ${portfolio.email}.`
      this.layouts.actives.backBtn = setting.action
      this.layouts.data.SVGControl = setting.SVGControl
    },
    returnHome () {
      this.$router.push('/guidance')
    },
    onClickSignIn () {
      this.$router.push('/sign-in')
    },
    onClickRegister () {
      this.$router.push('/send-set-link')
    }
  }
}
</script>

<style lang='sass' scoped>
.main
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  & .main-content
    display: flex
    flex-direction: column
    align-items: center
    width: 30%
  & .footer-wrap
    width: 15%
    margin-top: 100px
  & .bottom-wrap
    position: fixed
    bottom: 0
    z-index: 999
.primary-text
  cursor: pointer
  color: var(--theme_primary)
</style>
